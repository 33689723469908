import { FC, useEffect, useState } from "react";
import "./index.scss";
import CustomSelect from "../../../components/CustomSelect";
import {
  getDatacenterPlanAndVariantFromProductID,
  getTokenFromStorage,
} from "../../../utils/util";
import { toast } from "react-toastify";
import {
  datacenterPlans,
  infinityClubMembership,
  residentialPlans,
} from "../../../config";

const Overview: FC = () => {
  const [checkouts, setCheckouts] = useState([] as Checkout[]);
  const [subscriptions, setSubscriptions] = useState([] as Subscription[]);
  const [monthlyRevenue, setMonthlyRevenue] = useState(0);
  const [announcementText, setAnnouncementText] = useState("");

  const addNews = () => {
    const token = getTokenFromStorage();

    fetch("https://api.infinity-supply.eu/api/admin/announcements", {
      method: "POST",
      headers: {
        authorization: "Bearer " + token,
      },
      body: JSON.stringify({ message: announcementText }),
    })
      .then(async (response) => {
        if (response.status >= 400) {
          alert((await response.json())["message"]);
          return;
        }

        toast("Successfully sent your announcement");
      })
      .catch((error) => {
        console.log(error);
      });

    (
      document.getElementById("announcement-text") as HTMLTextAreaElement
    ).value = "";
  };

  const getExchangeRates = async (base: string, amount: number) => {
    return await fetch(
      `https://exchangerate-api.joeyli.dev/api/v1/rates?base=${base}&amount=${amount}`
    ).then((response) => response.json());
  };

  useEffect(() => {
    const token = getTokenFromStorage();
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    fetch("https://api.infinity-supply.eu/api/admin/checkouts", {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.status >= 400) {
          toast((await response.json())["message"]);
          return;
        }

        const rates: Currencies = await getExchangeRates("EUR", 1);

        const checkouts: Checkout[] = await response.json();
        checkouts.reverse();
        setCheckouts(checkouts);

        const revenue: number = checkouts
          .filter((checkout) => {
            const created = new Date(checkout.created_at);
            return (
              created.getFullYear() === currentYear &&
              created.getMonth() === currentMonth
            );
          })
          .map(
            (checkout) =>
              parseFloat(checkout.price.split(" ")[0]) /
              rates.currencies[checkout.price.split(" ")[1]].rate
          )
          .reduce((prev, current) => prev + current);
        setMonthlyRevenue(revenue);
      })
      .catch((error) => {
        console.log(error);
      });

    fetch("https://api.infinity-supply.eu/api/admin/subscriptions", {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.status >= 400) {
          toast((await response.json())["message"]);
          return;
        }

        const subscriptions: Subscription[] = await response.json();
        setSubscriptions(subscriptions);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div className="overview">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
          <div className="card1">
            <div className="overview-title1">Accouncement</div>
            <div className="overview-title2">Add News</div>
            <div className="box">
              <textarea
                id="announcement-text"
                maxLength={16000}
                spellCheck={false}
                onChange={(event) => setAnnouncementText(event.target.value)}
              />
            </div>
            <div className="btn-group ">
              <button className="add" onClick={addNews}>
                Add News
              </button>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
          <div className="card1">
            <div className="header">
              <div className="overview-title1">Monthly Overview</div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-md-4">
                <div className="month">Sold this Month</div>
                <div className="price">€ {monthlyRevenue.toFixed(2)}</div>
              </div>
            </div>
            <div className="table-container month-overview">
              <table className="subnet-table">
                <thead className="table-header">
                  <tr>
                    <th className="th-plan">Plan</th>
                    <th className="th-quantity">Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {datacenterPlans.map((datacenterPlan, idx) => {
                    const variantIds = datacenterPlan.variants.map(
                      (variant) => variant.product_id
                    );
                    const subscriptionQuantities = subscriptions
                      .filter((subscription) =>
                        variantIds.includes(subscription.subscription_plan_id)
                      )
                      .map(
                        (subscription) =>
                          datacenterPlan.variants.filter(
                            (variant) =>
                              variant.product_id ===
                              subscription.subscription_plan_id
                          )[0].amount
                      );

                    return (
                      <tr
                        key={idx}
                        className="animate__animated animate__fadeInUp"
                      >
                        <td className="th-plan">
                          <div className="plan-title">
                            {datacenterPlan.title}
                          </div>
                        </td>
                        <td>
                          <div className="quantity-title">
                            {subscriptionQuantities.length > 0
                              ? subscriptionQuantities.reduce(
                                  (prev, current) => prev + current
                                )
                              : 0}
                          </div>
                        </td>
                      </tr>
                    );
                  })}

                  {residentialPlans.map((residentialPlan, idx) => {
                    const today = new Date();
                    const checkoutQuantities = checkouts
                      .filter((checkout) => {
                        const created = new Date(checkout.created_at);

                        return (
                          created.getMonth() === today.getMonth() &&
                          created.getFullYear() == today.getFullYear()
                        ); // Only from this month
                      })
                      .filter(
                        (checkout) =>
                          checkout.product_name === residentialPlan.title // Only the product
                      )
                      .map((checkout) => parseFloat(checkout.quantity));

                    return (
                      <tr
                        key={idx}
                        className="animate__animated animate__fadeInUp"
                      >
                        <td className="th-plan">
                          <div className="plan-title">
                            {residentialPlan.title}
                          </div>
                        </td>
                        <td>
                          <div className="quantity-title">
                            {checkoutQuantities.length > 0
                              ? checkoutQuantities.reduce(
                                  (prev, current) => prev + current
                                )
                              : 0}{" "}
                            GB
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  <tr className="animate__animated animate__fadeInUp">
                    <td className="th-plan">
                      <div className="plan-title">
                        {" "}
                        {infinityClubMembership.title}
                      </div>
                    </td>
                    <td>
                      <div className="quantity-title">
                        {
                          subscriptions.filter(
                            (subscription) =>
                              subscription.subscription_plan_id ===
                              infinityClubMembership.product_id
                          ).length
                        }
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
          <div className="card1">
            <div className="header">
              <div className="overview-title1">Orders</div>
            </div>
            <div>
              <div className="table-container">
                <table className="subnet-table">
                  <thead className="table-header">
                    <tr>
                      <th className="th-plan">Plan</th>
                      <th className="th-quantity">Quantity</th>
                      <th className="th-user">User</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkouts.map((checkout, idx) => (
                      <tr
                        key={idx}
                        className="animate__animated animate__fadeInUp"
                      >
                        <td className="th-plan">
                          <div className="plan-title">
                            {checkout.product_name}
                          </div>
                        </td>
                        <td>
                          <div className="quantity-title">
                            {checkout.quantity}
                          </div>
                        </td>
                        <td>
                          <div className="quantity-title">{checkout.buyer}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
