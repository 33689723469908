import { FC, useEffect, useState } from "react";
import NavBar from "../../components/Navbar";
import MembershipPlan from "./MembershipPlan";
import ChangePassword from "./ChangePassword";
import Checkouts from "./Checkouts";
import "./style.scss";
import { getTokenFromStorage } from "../../utils/util";

const MyAccount: FC<{ user: User | undefined }> = ({ user }) => {
  const [checkouts, setCheckouts] = useState([] as Checkout[]);
  const [ip, setIP] = useState("");
  const [country, setCountry] = useState("");

  useEffect(() => {
    const token = getTokenFromStorage();

    fetch("https://api.infinity-supply.eu/api/user/checkouts", {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        const json = await response.json();
        json.reverse();
        setCheckouts(json);
      })
      .catch((error) => {
        console.log(error);
      });

    fetch("https://1.1.1.1/cdn-cgi/trace")
      .then((response) => response.text())
      .then((content) => {
        content.split("\n").forEach((contentLines) => {
          const contentParts = contentLines.split("=");

          if (contentParts[0] === "ip") {
            setIP(contentParts[1]);
          }

          if (contentParts[0] === "loc") {
            setCountry(contentParts[1]);
          }
        });
      });
  }, []);

  return (
    <div className="my-account fade-in">
      <div className="row">
        <NavBar user={user} />
      </div>
      <div className="content">
        <div className="row mt-4">
          <div className="col-md-9 col-lg-6">
            <div className="row mb-2">
              <div className="col-lg-12">
                <div className="overview-content">
                  <h4 className="name mb-4">Welcome back, {user?.username}</h4>

                  <div className="row">
                    <div className="col-md-6">
                      <h4 className="name mb-md-4 mb-3">Overview</h4>

                      <span className="total-title">Total Spent</span>
                      <div className="amount">
                        {checkouts.length === 0
                          ? ""
                          : checkouts
                              .map((checkout) =>
                                parseFloat(checkout.price.split(" ")[0])
                              )
                              .reduce((prev, next) => prev + next).toFixed(2)}{" "}
                              
                        {checkouts.length === 0
                          ? ""
                          : checkouts[0].price.split(" ")[1]}
                      </div>
                    </div>

                    <div className="col-md-6 mt-5 mt-md-0">
                      <h4 className="name mb-md-4 mb-3">
                        Your Public IP Address
                      </h4>

                      <span className="total-title d-md-block d-none">
                        {" "}
                        &nbsp;
                      </span>
                      <div className="amount">
                        <img
                          src={
                            country === ""
                              ? "https://via.placeholder.com/500"
                              : `https://flagsapi.com/${country}/flat/64.png`
                          }
                          alt=""
                        />
                        {ip}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-2">
              <MembershipPlan />
            </div>
            <div className="row">
              <div className="col-lg-12">
                {user?.discord_id ? null : <ChangePassword />}
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <Checkouts checkouts={checkouts} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
