import { FC, useState } from "react";
import { toast } from "react-toastify";

type tplanGenerationData = {
    [key: string]: {
      port: number,
      host: string
    },
}

const planGenerationData: tplanGenerationData = {
    "Infinity Residential": {
      host: "residential.infinity-supply.eu",
      port: 7000
    },
    "Smart Residential": {
      host: "smart.infinity-supply.eu",
      port: 8000
    },
    "Oxy Residential": {
      host: "oxy.infinity-supply.eu",
      port: 9000
    },
    "Scale Residential": {
      host: "scale.infinity-supply.eu",
      port: 10000
    }
}

const Log: FC<{user: User | undefined, plan: string, type: string, pool: ResidentialPool, amount: number}> = ({ user, plan, type, pool, amount }) => {
  const [proxies, setProxies] = useState([] as string[]);

  const generateSession = () => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 8; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const generateProxies = () => {
    const newProxies = [];
    const countryCode = pool.code;
    const planData = planGenerationData[plan]
    for (let index = 0; index < amount; index++) {
      let username = user?.subuser.username + "-cc-" + countryCode;
      if (type === "Static") {
        username += "-session-" + generateSession();
      }

      newProxies.push(`${planData.host}:${planData.port}:${username}:${user?.subuser.password}`)
    }
    setProxies(newProxies);
    toast("Successfully generated proxies")
  };

  const clear = () => {
    setProxies([]);
    toast("Successfully cleared proxies")
  };

  const copy = () => {
    navigator.clipboard.writeText(proxies.join("\n")).then(() => {
      toast("Successfully copied proxies to your clipboard")
    })
  };

  const exportProxies = () => {
    const url = window.URL.createObjectURL(new Blob([proxies.join("\n")]));
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;

    a.download = 'proxies.txt';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="proxies">
      <div className="dashboard-card log">
        <p>
          {proxies.map((pr) => (
            <span className="d-block">
              {pr}
            </span>
          ))}
        </p>
      </div>

      <div className="buttons w-100">
        <button onClick={generateProxies}>Generate Proxies</button>
        <button onClick={copy}>Copy Proxies</button>
        <button onClick={clear}>Clear Proxies</button>
        <button onClick={exportProxies}>Export Proxies</button>
      </div>
    </div>
  );
};

export default Log;
