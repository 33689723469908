import { FC, useState } from "react";
import { toast } from "react-toastify";
import CustomSelect from "../../components/CustomSelect";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import AnimatedNumber from "react-animated-number";

const ShopItem: FC<{
  plans: DatacenterPlan[];
  stock: Stock[];
  onPurchase: Function;
}> = ({ plans, stock, onPurchase }) => {
  const [selectedCategory, setSelectedCategory] = useState(
    plans.map((plans) => plans.category)[0]
  );
  const [selectedPlan, setSelectedPlan] = useState(plans[0]);
  const [sliderValue, setSliderValue] = useState(100);

  const getStockForPlan = (plan: DatacenterPlan, stock: Stock[]): number => {
    const productStock = stock.filter(
      (stock) => stock.product_name === plan.title
    )[0];
    return productStock ? productStock.amount : 0;
  };

  const getPriceFromPlanAndAmount = (
    plan: DatacenterPlan,
    amount: number
  ): number => {
    return plan.variants.filter((variant) => variant.amount === amount)[0]
      .price;
  };

  return (
    <div className="shop-card" style={{ height: "unset" }}>
      <div className="card-title">{selectedPlan.title}</div>
      <div className="card-detail mb-md-4 mb-3">{selectedPlan.description}</div>
      <div className="card-footer d-block">
        <h4 className="name mb-md-4 mb-3">Choose Product:</h4>
        <div className="group-input">
          <CustomSelect
            options={plans
              .map((plans) => plans.category)
              .filter((value, index, self) => self.indexOf(value) === index)}
            handler={(option: string) => {
              setSelectedCategory(option);
              setSelectedPlan(
                plans.filter((plan) => plan.category === option)[0]
              );
            }}
            selected={selectedCategory}
          />
        </div>

        <div className="group-input mt-3">
          <CustomSelect
            options={plans
              .filter((plan) => plan.category === selectedCategory)
              .map((plan: DatacenterPlan) => (
                <>
                  <img src={plan.icon} alt="" /> {plan.title}
                </>
              ))}
            handler={(option: JSX.Element) => {
              setSliderValue(25)
              setSelectedPlan(
                plans.filter(
                  (plan) => plan.title === option.props.children[2]
                )[0]
              );
            }}
            selected={
              <>
                <img src={selectedPlan.icon} alt="" /> {selectedPlan.title}
              </>
            }
          />
        </div>

        <h1 className="price">
          <AnimatedNumber
            value={getPriceFromPlanAndAmount(selectedPlan, sliderValue)}
            formatValue={(n: number) => n.toFixed(0)}
            className="price"
          />
          .00 € / Monthly
        </h1>
        <p className="price2">
          {getPriceFromPlanAndAmount(selectedPlan, sliderValue) / sliderValue} €
          per IP incl. VAT
        </p>

        <div className="slider-wrap">
          <label>Quantity</label>
          <Slider
            min={25}
            max={
              selectedPlan.title === "Deutsche Telekom AG Ticket" || selectedPlan.title === "Datacenter Proxies DE"
                ? 200
                : 250
            }
            value={sliderValue}
            defaultValue={sliderValue}
            marks={
              selectedPlan.title === "Deutsche Telekom AG Ticket" || selectedPlan.title === "Datacenter Proxies DE"
                ? {
                    25: 25,
                    50: 50,
                    // 75: 75,
                    100: 100,
                    // 125: 125,
                    150: 150,
                    // 175: 175,
                    200: 200,
                    // 225: 225,
                  }
                : {
                    25: 25,
                    50: 50,
                    // 75: 75,
                    100: 100,
                    // 125: 125,
                    150: 150,
                    // 175: 175,
                    200: 200,
                    // 225: 225,
                    250: 250,
                  }
            }
            step={null}
            onChange={(value: any) => {
              setSliderValue(value);
            }}
          />
        </div>

        <div className="card-detail mt-5">
          For Business Customers VAT can be removed at Checkout. We offer B2B 0%
          VAT Worldwide.
        </div>

        <button
          className="purchase mt-4 d-block ms-auto"
          disabled={getStockForPlan(selectedPlan, stock) < sliderValue}
          onClick={() => {
            if (getStockForPlan(selectedPlan, stock) < sliderValue) {
              toast("This plan is currently OOS");
              return;
            }
            onPurchase(
              selectedPlan,
              selectedPlan.variants.filter(
                (variant: { amount: number; price: number }) =>
                  variant.amount === sliderValue
              )[0]
            );
          }}
        >
          {
            /* {stock.amount <
          selectedPlan.variants.filter(
            (variant: { amount: number; price: number }) =>
              `${variant.amount} / ${variant.price} €` === selectedVariant
          )[0].amount
            ? "OOS"
            : "Purchase"} */
            getStockForPlan(selectedPlan, stock) >= sliderValue
              ? "Purchase"
              : "OOS"
          }
        </button>
      </div>
    </div>
  );
};

export default ShopItem;
