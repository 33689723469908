import { ChangeEvent } from "react";
import { datacenterPlans } from "../config";

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const sleep: any = async (m: any) =>
  await new Promise((r) => setTimeout(r, m));

export const maskCardNumber = (cardNumber: string) => {
  let cardNumberArr = cardNumber.split("");
  cardNumberArr.forEach((val, index) => {
    if (index >= 0 && index < 14) {
      if (cardNumberArr[index] !== " ") {
        cardNumberArr[index] = "*";
      }
    }
  });

  return cardNumberArr;
};

export const onChangeSetState = (setState: Function) => {
  return (event: ChangeEvent) => {
    setState((event.target as HTMLInputElement).value);
  };
};

export const financialSubtraction = (a: number, b: number) => {
  return parseFloat((a - b).toFixed(10));
};

export const getTokenFromStorage = (): string | null => {
  if (localStorage.getItem("token") !== null) {
      return localStorage.getItem("token")
  }
  return sessionStorage.getItem("token");
};


const getNumberWithOrdinal = (number: number) => {
  var s = ["th", "st", "nd", "rd"],
    v = number % 100;
  return number + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const dateSuffix = date.toLocaleDateString("en", {
    month: "short",
    year: "numeric",
  });
  const day = getNumberWithOrdinal(date.getDate());

  return `${day} ${dateSuffix}`;
};

export const getDatacenterPlanAndVariantFromProductID = (productId: number): [DatacenterPlan, DatacenterPlanVariant] => {
  const datacenterPlan = datacenterPlans.filter(plan => plan.variants.filter(variant => variant.product_id === productId).length !== 0)[0]
  if (!datacenterPlan) {
    return [{} as any, {} as any]
  }
  const datacenterPlanVariant = datacenterPlan.variants.filter(variant => variant.product_id === productId)[0]

  return [datacenterPlan, datacenterPlanVariant]
}

export const getDatacenterPlanName = ([plan,variant]: [DatacenterPlan, DatacenterPlanVariant]): string => {
  return `${variant.amount} ${plan.title}`
}