import { FC } from "react";
import Icon from "../../assets/icons/network.svg";
import "./style.scss";
import MoreIcon from "../../assets/icons/more.svg";

const Content: FC<{ setCard: Function; plan: Plan }> = ({ setCard, plan }) => {
  let progressWidth: number = 100 - (plan.data_used / plan.data_total) * 100;
  progressWidth = progressWidth < 0 ? 0 : progressWidth;

  return (
    <div className="w-100 h-100 fade-in">
      <div className="top">
        <div className="icon my-auto">
          <img src={Icon} alt="assignment late" />
        </div>
        <div className="title-date">
          <span className="title">{plan.type}</span>
        </div>
        <img
          src={MoreIcon}
          className="show-more"
          alt="Show more"
          onClick={() => setCard("choose")}
        />
      </div>
      <div className="bottom">
        <div className="progress-bar">
          <div style={{ height: "100%", width: progressWidth + "%" }}>
            <div
              className="progress"
              style={{
                backgroundColor:
                  plan.data_limit !== -1 && plan.data_limit < plan.data_used
                    ? "#ff6961"
                    : "",
              }}
            ></div>
          </div>
        </div>
        <div className="data">
          <div className="used-data">
            <div className="size">
              {(plan.data_used / 2 ** 30).toFixed(2)} GB
            </div>
            <div className="name">Used Data</div>
          </div>
          {plan.data_limit === -1 ? null : (
            <div className="used-data">
              <div className="size">
                {(plan.data_limit / 2 ** 30).toFixed(2)} GB
              </div>
              <div className="name">Plan Limit</div>
            </div>
          )}
          <div className="used-data">
            <div className="size">
              {(plan.data_total / 2 ** 30).toFixed(2)} GB
            </div>
            <div className="name">Total Data</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
