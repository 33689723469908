import { FC, useState } from "react";
import Choose from "./Choose";
import Content from "./Content";
import Remove from "./Remove";
import Set from "./Set";
import Success from "./Success";

import "./style.scss";

const ResPlan: FC<{plan: Plan}> = ({ plan }) => {
  const [card, setCard] = useState("content");

  return (
    <div className="dashboard-card res-plan">
      {card === "choose" ? (
        <Choose setCard={setCard} />
      ) : card === "set" ? (
        <Set setCard={setCard} plan={plan} />
      ) : card === "remove" ? (
        <Remove setCard={setCard} plan={plan} />
      ) : card === "success" ? (
        <Success setCard={setCard} />
      ) : (
        <Content setCard={setCard} plan={plan} />
      )}
    </div>
  );
};

export default ResPlan;
