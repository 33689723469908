import { FC, useEffect, useState } from "react";
import NavBar from "../../components/Navbar";
import CustomSelect from "../../components/CustomSelect";
import Table from "./Table";
import "./style.scss";
import {
  getDatacenterPlanAndVariantFromProductID,
  getTokenFromStorage,
} from "../../utils/util";
import { toast } from "react-toastify";
import { datacenterPlans } from "../../config";
import Fuse from "fuse.js";

const DataCenterOrders: FC<{ user: User | undefined }> = ({ user }) => {
  const [username, setUsername] = useState("");
  const [plan, setPlan] = useState(datacenterPlans[0].title);

  const [orders, setOrders] = useState([] as DatacenterOrder[]);
  const [sortedOrders, setSortedOrders] = useState([] as DatacenterOrder[]);
  const [subnets, setSubnets] = useState([] as Subnet[]);
  useEffect(() => {
    const token = getTokenFromStorage();

    fetch("https://api.infinity-supply.eu/api/admin/datacenterorders", {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.status >= 400) {
          toast((await response.json())["message"]);
          return;
        }
        const json = await response.json();
        setOrders(json);
        setSortedOrders(json);
      })
      .catch((error) => {
        console.log(error);
      });

    fetch("https://api.infinity-supply.eu/api/admin/listsubnets", {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.status >= 400) {
          toast((await response.json())["message"]);
          return;
        }
        const json = await response.json();
        setSubnets(json);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const searchOptions = {
    keys: ["user.username"],
  };

  return (
    <div className="datacenter-panel fade-in">
      <div className="row">
        <NavBar user={user} />
      </div>
      <div className="content">
        <div className="row">
          <div className="w-100 mt-4 justify-content-space-between align-center">
            <div className="title my-auto">Manage Datacenter Panel</div>
            <div
              className="group-input ms-auto"
              style={{ display: "flex", width: "500px" }}
            >
              <input
                type="text"
                className="input-text"
                placeholder="Username"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);

                  if (!e.target.value) {
                    setSortedOrders(orders);
                    return;
                  }

                  const fuse = new Fuse(
                    orders.filter(
                      (order) =>
                        getDatacenterPlanAndVariantFromProductID(
                          order.subscription_plan_id
                        )[0].title === plan
                    ),
                    searchOptions
                  );
                  setSortedOrders(
                    fuse.search(username).map((searchItem) => searchItem.item)
                  );
                }}
              />
              <CustomSelect
                options={datacenterPlans.map(
                  (datacenterPlan) => datacenterPlan.title
                )}
                handler={(newSelectedPlan: string) => {
                  setPlan(newSelectedPlan);

                  setSortedOrders(
                    orders.filter(
                      (order) =>
                        getDatacenterPlanAndVariantFromProductID(
                          order.subscription_plan_id
                        )[0].title === newSelectedPlan
                    )
                  );
                }}
                selected={plan}
              />
            </div>
          </div>
          <div className="table-responsive">
            <Table orders={sortedOrders} subnets={subnets} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataCenterOrders;
