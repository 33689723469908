import { FC, useEffect, useState } from "react";
import CustomSelect from "../../components/CustomSelect";
import {
  oxyResidentialPools,
  infinityResidentialPools,
  smartResidentialPools,
  scaleResidentialPools,
} from "../../config";

const Filters: FC<{
  user: User | undefined;
  plan: [string, Function];
  type: [string, Function];
  amount: [number, Function];
  pool: [ResidentialPool, Function];
}> = ({ user, plan, type, amount, pool }) => {
  const handleAmountChange = (event: { target: { value: any } }) => {
    amount[1](event.target.value);
  };

  const [pools, setPools] = useState(infinityResidentialPools);
  const [plans, setPlans] = useState([] as string[])

  useEffect(() => {
    const plans: string[] = [];
    user?.subuser.plans.forEach((plan) => {
      if (plan.data_limit !== -1 && plan.data_limit < plan.data_used) return;
      if (plan.data_total < plan.data_used) return;
      plans.push(plan.type);
    });

    plan[1](plans[0])

    setPlans(plans)
  }, [user]);

  return (
    <div className="dashboard-card filters">
      <div className="group-input">
        <label className="mb-3">Residential Plan</label>
        <CustomSelect
          options={plans}
          handler={(newPlan: string) => {
            plan[1](newPlan);
            switch (newPlan) {
              case "Infinity Residential":
                setPools(infinityResidentialPools);
                break;
              case "Smart Residential":
                setPools(smartResidentialPools);
                break;
              case "Oxy Residential":
                setPools(oxyResidentialPools);
                break;
              case "Scale Residential":
                  setPools(scaleResidentialPools);
                  break;
            }
          }}
          selected={plan[0]}
          blue
        />
      </div>

      <div className="group-input mt-3">
        <label className="mb-3">Type</label>
        <CustomSelect
          options={["Static", "Rotating"]}
          handler={type[1]}
          selected={type[0]}
        />
      </div>

      <div className="group-input mt-3">
        <label className="mb-3">Location</label>
        <CustomSelect
          options={pools.map((pool) => pool.name)}
          handler={(newPool: string) => {
            const selectedPool = pools.filter(
              (pool) => pool.name === newPool
            )[0];
            pool[1](selectedPool);
          }}
          selected={pool[0].name}
        />
      </div>

      <div className="group-input mt-3">
        <label className="mb-3">Amount</label>
        <input
          type="number"
          min={0}
          value={amount[0]}
          onChange={handleAmountChange}
        />
      </div>
    </div>
  );
};

export default Filters;
