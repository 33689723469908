import { FC, useEffect, useState } from "react";
import NavBar from "../../components/Navbar";
import CustomSelect from "../../components/CustomSelect";
import Card from "./Card";
import "./style.scss";
import { getTokenFromStorage } from "../../utils/util";
import { toast } from "react-toastify";

const DataCenterProxies: FC<{ user: User | undefined }> = ({ user }) => {
  // const [plan, setPlan] = useState("Infinity Zalanda DCs");

  const [orders, setOrders] = useState([] as DatacenterOrder[]);
  useEffect(() => {
    const token = getTokenFromStorage();

    fetch("https://api.infinity-supply.eu/api/user/datacenterorders", {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.status >= 400) {
          toast(await response.text());
          return;
        }
        const json = await response.json();
        setOrders(json);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="datacenter-proxies fade-in">
      <div className="row">
        <NavBar user={user} />
      </div>
      <div className="content">
        <div className="row">
          <div className="title mt-4">Datacenter Plans</div>
        </div>
        {/* <div className="row">
          <div className="group-input">
            <CustomSelect
              options={["Infinity Zalanda DCs", "Option 2", "Option 3"]}
              handler={setPlan}
              selected={plan}
            />
          </div>
        </div> */}

        <div className="row">
            {orders.map((order, idx) => <Card key={idx} order={order} />)}
        </div>
      </div>
    </div>
  );
};

export default DataCenterProxies;
