import de from "./assets/DE.png";
import nl from "./assets/NL.png";
import uk from "./assets/UK.png";
import au from "./assets/AU.png";
import us from "./assets/US.png";

export const residentialPlans: ResidentialPlan[] = [
  {
    title: "Infinity Residential",
    description:
      "Infinity Residential plan, gives you access to millions of unique proxies. Select from over 160 locations without any limitations. No expiring date and High Speed Performance.",
    product_id: 772727,
    price: 12,
    discounted_price: 10,
    variants: [
      {
        amount: 1,
        price: 12,
        discounted_price: 10,
      },
      {
        amount: 2,
        price: 24,
        discounted_price: 20,
      },
      {
        amount: 5,
        price: 60,
        discounted_price: 50,
      },
      {
        amount: 10,
        price: 120,
        discounted_price: 100,
      },
      {
        amount: 20,
        price: 240,
        discounted_price: 200,
      },
      {
        amount: 50,
        price: 600,
        discounted_price: 500,
      },
      {
        amount: 100,
        price: 1200,
        discounted_price: 1000,
      },
    ],
  },
  {
    title: "Smart Residential",
    description:
      "Smart Residential plan, gives you access to millions of unique proxies. Select from over 154 locations without any limitations. No expiring date and High Speed Performance.",
    product_id: 772728,
    price: 9.5,
    discounted_price: 6.8,
    variants: [
      {
        amount: 1,
        price: 9.5,
        discounted_price: 6.8,
      },
      {
        amount: 2,
        price: 19,
        discounted_price: 13.6,
      },
      {
        amount: 5,
        price: 47.5,
        discounted_price: 34,
      },
      {
        amount: 10,
        price: 95,
        discounted_price: 68,
      },
      {
        amount: 20,
        price: 190,
        discounted_price: 136,
      },
      {
        amount: 50,
        price: 475,
        discounted_price: 340,
      },
      {
        amount: 100,
        price: 950,
        discounted_price: 680,
      },
    ],
  },
  {
    title: "Oxy Residential",
    description:
      "Oxy Residential plan, gives you access to millions of unique proxies. Select from over 172 locations without any limitations. No expiring date and High Speed Performance.",
    product_id: 774437,
    price: 10,
    discounted_price: 7.8,
    variants: [
      {
        amount: 1,
        price: 10,
        discounted_price: 7.8,
      },
      {
        amount: 2,
        price: 20,
        discounted_price: 15.6,
      },
      {
        amount: 5,
        price: 50,
        discounted_price: 39,
      },
      {
        amount: 10,
        price: 100,
        discounted_price: 78,
      },
      {
        amount: 20,
        price: 200,
        discounted_price: 156,
      },
      {
        amount: 50,
        price: 500,
        discounted_price: 390,
      },
      {
        amount: 100,
        price: 1000,
        discounted_price: 780,
      },
    ],
  },
  {
    title: "Scale Residential",
    description:
      "Buying our Scale Residential plan, gives you access to millions of unique proxies. Select from over 155+ locations without any limitations. No expiring date and High Speed Performance.",
    product_id: 860002,
    price: 290,
    discounted_price: 290,
    variants: [
      {
        amount: 1,
        price: 2.9,
        discounted_price: 2.9,
      },
      {
        amount: 2,
        price: 5.4,
        discounted_price: 5.4,
      },
      {
        amount: 5,
        price: 13.5,
        discounted_price: 13.5,
      },
      {
        amount: 10,
        price: 27,
        discounted_price: 27,
      },
      {
        amount: 20,
        price: 54,
        discounted_price: 54,
      },
      {
        amount: 50,
        price: 135,
        discounted_price: 135,
      },
      {
        amount: 100,
        price: 290,
        discounted_price: 290,
      },
    ],
  },
];

export const datacenterPlans: DatacenterPlan[] = [
  {
    title: "Deutsche Telekom AG",
    category: "Internet Service Provider (ISPs)",
    icon: de,
    description:
      "Your own private dedicated proxies hosted on Top Tier ISP Lines. Highest Quality as possible, unblocked on 99% of the web. Monthly charged. Subscription can be canceled at any time.",
    variants: [
      {
        amount: 25,
        price: 50,
        product_id: 772679,
      },
      {
        amount: 50,
        price: 100,
        product_id: 772680,
      },
      {
        amount: 75,
        price: 150,
        product_id: 772681,
      },
      {
        amount: 100,
        price: 200,
        product_id: 772682,
      },
      {
        amount: 150,
        price: 300,
        product_id: 772684,
      },
      {
        amount: 200,
        price: 390,
        product_id: 772685,
      },
      {
        amount: 250,
        price: 480,
        product_id: 772686,
      },
    ],
  },
  {
    title: "Deutsche Telekom AG Ticket",
    category: "Internet Service Provider (ISPs)",
    icon: de,
    description:
      "Your own private dedicated proxies hosted on Top Tier ISP Lines. Highest Quality as possible, unblocked on 99% of the web. Monthly charged. Subscription can be canceled at any time.",
    variants: [
      {
        product_id: 843903,
        price: 50,
        amount: 25,
      },
      {
        product_id: 843904,
        price: 100,
        amount: 50,
      },
      {
        product_id: 843906,
        price: 150,
        amount: 75,
      },
      {
        product_id: 843909,
        price: 190,
        amount: 100,
      },
      {
        product_id: 843910,
        price: 280,
        amount: 150,
      },
      {
        product_id: 843911,
        price: 370,
        amount: 200,
      },
    ],
  },
  {
    title: "Virgin Media",
    category: "Internet Service Provider (ISPs)",
    icon: uk,
    description:
      "Your own private dedicated proxies hosted on Top Tier ISP Lines. Highest Quality as possible, unblocked on 99% of the web. Monthly charged. Subscription can be canceled at any time.",
    variants: [
      {
        product_id: 843804,
        price: 45,
        amount: 25,
      },
      {
        product_id: 843805,
        price: 90,
        amount: 50,
      },
      {
        product_id: 843806,
        price: 135,
        amount: 75,
      },
      {
        product_id: 843807,
        price: 180,
        amount: 100,
      },
      {
        product_id: 843808,
        price: 270,
        amount: 150,
      },
      {
        product_id: 843809,
        price: 360,
        amount: 200,
      },
      {
        product_id: 843810,
        price: 450,
        amount: 250,
      },
    ],
  },
  {
    title: "Vocus Fibre",
    category: "Internet Service Provider (ISPs)",
    icon: au,
    description:
      "Your own private dedicated proxies hosted on Top Tier ISP Lines. Highest Quality as possible, unblocked on 99% of the web. Monthly charged. Subscription can be canceled at any time.",
    variants: [
      {
        amount: 25,
        price: 50,
        product_id: 843797,
      },
      {
        amount: 50,
        price: 100,
        product_id: 843798,
      },
      {
        amount: 75,
        price: 140,
        product_id: 843799,
      },
      {
        amount: 100,
        price: 185,
        product_id: 843800,
      },
      {
        amount: 150,
        price: 280,
        product_id: 843801,
      },
      {
        amount: 200,
        price: 370,
        product_id: 843802,
      },
      {
        amount: 250,
        price: 450,
        product_id: 843803,
      },
    ],
  },
  {
    title: "Premium Ticket",
    category: "Internet Service Provider (ISPs)",
    icon: us,
    description:
      "Your own private dedicated proxies hosted on Top Tier ISP Lines. Highest Quality as possible, unblocked on 99% of the web. Monthly charged. Subscription can be canceled at any time.",
    variants: [
      {
        amount: 25,
        price: 45,
        product_id: 843433,
      },
      {
        amount: 50,
        price: 100,
        product_id: 843435,
      },
      {
        amount: 75,
        price: 135,
        product_id: 843437,
      },
      {
        amount: 100,
        price: 180,
        product_id: 843438,
      },
      {
        amount: 150,
        price: 270,
        product_id: 843440,
      },
      {
        amount: 200,
        price: 350,
        product_id: 843442,
      },
      {
        amount: 250,
        price: 430,
        product_id: 843444,
      },
    ],
  },
  {
    title: "Datacenter Proxies",
    category: "Datacenter (DCs)",
    icon: de,
    description:
      "Your own private dedicated proxies. You don't share it with anyone, you also get unlimited bandwidth with up to 10Gbps speeds. Monthly charged. Subscription can be canceled at any time.",
    variants: [
      {
        amount: 25,
        price: 45,
        product_id: 772669,
      },
      {
        amount: 50,
        price: 90,
        product_id: 772670,
      },
      {
        amount: 75,
        price: 135,
        product_id: 772671,
      },
      {
        amount: 100,
        price: 180,
        product_id: 772673,
      },
      {
        amount: 150,
        price: 270,
        product_id: 772674,
      },
      {
        amount: 200,
        price: 350,
        product_id: 772677,
      },
      {
        amount: 250,
        price: 430,
        product_id: 772678,
      },
    ],
  },
  {
    title: "Datacenter Proxies DE",
    category: "Datacenter (DCs)",
    icon: de,
    description:
      "Your own private dedicated proxies. You don't share it with anyone, you also get unlimited bandwidth with up to 10Gbps speeds. Monthly charged. Subscription can be canceled at any time.",
    variants: [
      {
        product_id: 860001,
        price: 37.50,
        amount: 25,
      },
      {
        product_id: 860068,
        price: 75,
        amount: 50,
      },
      {
        product_id: 860069,
        price: 112.5,
        amount: 75,
      },
      {
        product_id: 860070,
        price: 150,
        amount: 100,
      },
      {
        product_id: 860072,
        price: 225,
        amount: 150,
      },
      {
        product_id: 860073,
        price: 300,
        amount: 200,
      },
      // {
      //   "product_id": 860075,
      //   "price": 375,
      //   "amount": 254
      // }
    ],
  },
];

const defaultResidentialPool: ResidentialPool[] = [
  { name: "Germany", code: "DE" },
  { name: "United Kingdom", code: "GB" },
  { name: "Netherlands", code: "NL" },
  { name: "Spain", code: "ES" },
  { name: "United States of America", code: "US" },
  { name: "Canada", code: "CA" },
  { name: "France", code: "FR" },
  { name: "Italy", code: "IT" },
  { name: "Sweden", code: "SE" },
  { name: "Greece", code: "GR" },
  { name: "Portugal", code: "PT" },
  { name: "Belgium", code: "BE" },
  { name: "Russian Federation", code: "RU" },
  { name: "Ukraine", code: "UA" },
  { name: "Poland", code: "PL" },
  { name: "Israel", code: "IL" },
  { name: "Turkey", code: "TR" },
  { name: "Australia", code: "AU" },
  { name: "Malaysia", code: "MY" },
  { name: "Thailand", code: "TH" },
  { name: "South Korea", code: "KR" },
  { name: "Japan", code: "JP" },
  { name: "Philippines", code: "PH" },
  { name: "Singapore", code: "SG" },
  { name: "People's Republic of China", code: "CN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "India", code: "IN" },
  { name: "Pakistan", code: "PK" },
  { name: "Islamic Republic of Iran", code: "IR" },
  { name: "Indonesia", code: "ID" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "Mexico", code: "MX" },
  { name: "Brazil", code: "BR" },
  { name: "Argentina", code: "AR" },
  { name: "Chile", code: "CL" },
  { name: "Peru", code: "PE" },
  { name: "Ecuador", code: "EC" },
  { name: "Colombia", code: "CO" },
  { name: "South Africa", code: "ZA" },
  { name: "Egypt", code: "EG" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Denmark", code: "DK" },
  { name: "Angola", code: "AO" },
  { name: "Cameroon", code: "CM" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Benin", code: "BJ" },
  { name: "Ethiopia", code: "ET" },
  { name: "Djibouti", code: "DJ" },
  { name: "Republic of The Gambia", code: "GM" },
  { name: "Ghana", code: "GH" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Kenya", code: "KE" },
  { name: "Liberia", code: "LR" },
  { name: "Madagascar", code: "MG" },
  { name: "Mali", code: "ML" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Nigeria", code: "NG" },
  { name: "Senegal", code: "SN" },
  { name: "Seychelles", code: "SC" },
  { name: "Zimbabwe", code: "ZW" },
  { name: "South Sudan", code: "SS" },
  { name: "Sudan", code: "SD" },
  { name: "Togo", code: "TG" },
  { name: "Tunisia", code: "TN" },
  { name: "Uganda", code: "UG" },
  { name: "Zambia", code: "ZM" },
  { name: "Afghanistan", code: "AF" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Armenia", code: "AM" },
  { name: "Bhutan", code: "BT" },
  { name: "Myanmar", code: "MM" },
  { name: "Cambodia", code: "KH" },
  { name: "Georgia", code: "GE" },
  { name: "Iraq", code: "IQ" },
  { name: "Jordan", code: "JO" },
  { name: "Lebanon", code: "LB" },
  { name: "Maldives", code: "MV" },
  { name: "Mongolia", code: "MN" },
  { name: "Oman", code: "OM" },
  { name: "Qatar", code: "QA" },
  { name: "Vietnam", code: "VN" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Yemen", code: "YE" },
  { name: "Albania", code: "AL" },
  { name: "Andorra", code: "AD" },
  { name: "Austria", code: "AT" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Bulgaria", code: "BG" },
  { name: "Belarus", code: "BY" },
  { name: "Croatia", code: "HR" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Estonia", code: "EE" },
  { name: "Finland", code: "FI" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "Ireland", code: "IE" },
  { name: "Latvia", code: "LV" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Malta", code: "MT" },
  { name: "Monaco", code: "MC" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Montenegro", code: "ME" },
  { name: "Norway", code: "NO" },
  { name: "Romania", code: "RO" },
  { name: "Serbia", code: "RS" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Switzerland", code: "CH" },
  { name: "The Republic of North Macedonia", code: "MK" },
  { name: "Bahamas", code: "BS" },
  { name: "Belize", code: "BZ" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cuba", code: "CU" },
  { name: "Dominica", code: "DM" },
  { name: "Haiti", code: "HT" },
  { name: "Honduras", code: "HN" },
  { name: "Jamaica", code: "JM" },
  { name: "Aruba", code: "AW" },
  { name: "Panama", code: "PA" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Fiji", code: "FJ" },
  { name: "New Zealand", code: "NZ" },
  { name: "Bolivia", code: "BO" },
  { name: "Paraguay", code: "PY" },
  { name: "Uruguay", code: "UY" },
  { name: "Venezuela", code: "VE" },
  { name: "Virgin Islands, British", code: "VG" },
];

export const infinityResidentialPools: ResidentialPool[] = [
  ...defaultResidentialPool.slice(0, 4),
  {
    name: "Footlocker EU",
    code: "FootlockerEU",
  },
  {
    name: "Footlocker AU",
    code: "FootlockerAU",
  },
  {
    name: "Footlocker SG",
    code: "FootlockerSG",
  },
  {
    name: "EU Mix",
    code: "EUMIX",
  },
  {
    name: "Zalando",
    code: "Zalando",
  },
  {
    name: "Snipes",
    code: "Snipes",
  },
  {
    name: "Solebox",
    code: "Solebox",
  },
  {
    name: "Courir",
    code: "Courir",
  },
  {
    name: "Mesh",
    code: "Mesh",
  },
  {
    name: "NBB",
    code: "NBB",
  },
  {
    name: "MMS",
    code: "MMS",
  },
  {
    name: "Mesh Elite",
    code: "MeshElite",
  },
  {
    name: "Courir Elite",
    code: "CourirElite",
  },
  ...defaultResidentialPool.slice(4),
];

export const smartResidentialPools: ResidentialPool[] = [
  ...defaultResidentialPool.slice(0, 4),
  {
    name: "Footlocker EU",
    code: "FootlockerEU",
  },
  {
    name: "Footlocker AU",
    code: "FootlockerAU",
  },
  {
    name: "Footlocker SG",
    code: "FootlockerSG",
  },
  {
    name: "EU Mix",
    code: "EUMIX",
  },
  {
    name: "Zalando",
    code: "Zalando",
  },
  {
    name: "Snipes",
    code: "Snipes",
  },
  {
    name: "Solebox",
    code: "Solebox",
  },
  {
    name: "Courir",
    code: "Courir",
  },
  {
    name: "Mesh",
    code: "Mesh",
  },
  {
    name: "NBB",
    code: "NBB",
  },
  {
    name: "MMS",
    code: "MMS",
  },
  ...defaultResidentialPool.slice(4),
];

export const oxyResidentialPools: ResidentialPool[] = [
  ...defaultResidentialPool.slice(0, 4),
  {
    name: "Footlocker EU",
    code: "FootlockerEU",
  },
  {
    name: "Footlocker AU",
    code: "FootlockerAU",
  },
  {
    name: "Footlocker SG",
    code: "FootlockerSG",
  },
  {
    name: "EU Mix",
    code: "EUMIX",
  },
  {
    name: "Zalando",
    code: "Zalando",
  },
  {
    name: "Snipes",
    code: "Snipes",
  },
  {
    name: "Solebox",
    code: "Solebox",
  },
  {
    name: "Courir",
    code: "Courir",
  },
  {
    name: "Mesh",
    code: "Mesh",
  },
  {
    name: "NBB",
    code: "NBB",
  },
  {
    name: "MMS",
    code: "MMS",
  },
  ...defaultResidentialPool.slice(4),
];

export const scaleResidentialPools: ResidentialPool[] = [
  ...defaultResidentialPool.slice(0, 4),
  // Extra
  {
    name: "Ticket Elite US",
    code: "TicketEliteUS",
  },
  ...defaultResidentialPool.slice(4),
];

export const infinityClubMembership = {
  title: "Infinity Club Membership",
  product_id: 772667,
  price: 20,
};

export const discordLoginURL: string =
  "https://discord.com/oauth2/authorize?client_id=961314898514694165&redirect_uri=https://dash.infinity-supply.eu/login&response_type=code&scope=identify";
