import { FC, useEffect, useState } from "react";
import Fuse from "fuse.js";
import { toast } from "react-toastify";
import User from "./User";
import CustomMultiSelect from "../../../components/CustomMultiSelect";
import { getTokenFromStorage } from "../../../utils/util";

import "./index.scss";
import CustomSelect from "../../../components/CustomSelect";
import { residentialPlans } from "../../../config";

const Balance: FC = () => {
  const token = getTokenFromStorage();

  const [addBalanceUsername, setAddBalanceUsername] = useState("");
  const [removeBalanceUsername, setRemoveBalanceUsername] = useState("");
  const [addBalanceAmount, setAddBalanceAmount] = useState(0);
  const [removeBalanceAmount, setRemoveBalanceAmount] = useState(0);
  const [addBalanceType, setAddBalanceType] = useState(
    residentialPlans[0].title
  );
  const [removeBalanceType, setRemoveBalanceType] = useState(
    residentialPlans[0].title
  );

  const [users, setUsers] = useState([] as User[]);
  const [filteredUsers, setFilteredUsers] = useState([] as User[]);

  const searchOptions = {
    keys: ["username"],
  };

  const fetchUsers = () => {
    fetch("https://api.infinity-supply.eu/api/admin/users", {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.status >= 400) {
          toast("There was a problem getting users.");
          return;
        }
        const json = await response.json();
        setUsers(json);
        setFilteredUsers(json);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeBalance = (
    username: string,
    type: string,
    amount: number,
    remove: boolean
  ) => {
    if (!username) {
      toast("You have to supply a username");
      return;
    }

    if (amount <= 0) {
      toast("You have to add an amount bigger than 0");
      return;
    }

    if (remove) {
      amount = amount * -1;
    }

    const payload = {
      username,
      amount,
      type,
    };

    const token = getTokenFromStorage();
    fetch("https://api.infinity-supply.eu/api/admin/changebalance", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.status >= 400) {
          toast((await response.json()).message);
          return;
        }

        toast("You've successfully changed this user's balance.");
        setTimeout(() => {
          fetchUsers();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const banUser = (id: number) => {
    const token = getTokenFromStorage();
    fetch("https://api.infinity-supply.eu/api/admin/banuser", {
      method: "POST",
      body: JSON.stringify({ id }),
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.status >= 400) {
          alert((await response.json()).message);
          return;
        }

        toast("You've successfully banned this user.");
        setTimeout(() => {
          fetchUsers();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="balance">
      <div className="row">
        <div className="col-sm-12 col-md-4">
          <div className="balance-card1">
            <div className="balance-title1">Add Balance</div>
            <div className="balance-title2">Amount</div>
            <div style={{ height: "44px", width: "100%" }}>
              <input
                type="number"
                className="input-balance"
                placeholder="0"
                value={addBalanceAmount}
                onChange={(e) =>
                  setAddBalanceAmount(parseFloat(e.target.value))
                }
              />
            </div>
            <div className="name">Type</div>
            <div className="group-input">
              <CustomSelect
                options={residentialPlans.map((plan) => plan.title)}
                handler={setAddBalanceType}
                selected={addBalanceType}
              />
            </div>
            <div className="name">Username</div>
            <div className="group-input">
              <input
                type="text"
                className="input-balance"
                placeholder="Username"
                value={addBalanceUsername}
                onChange={(e) => setAddBalanceUsername(e.target.value)}
              />
            </div>
            <button
              className="add-balance"
              onClick={() =>
                changeBalance(
                  addBalanceUsername,
                  addBalanceType,
                  addBalanceAmount,
                  false
                )
              }
            >
              Add Balance
            </button>
          </div>
          <div className="balance-card2">
            <div className="balance-title1">Remove Balance</div>
            <div className="balance-title2">Amount</div>
            <div style={{ height: "44px", width: "100%" }}>
              <div className="group-input">
                <input
                  type="number"
                  className="input-balance"
                  placeholder="0"
                  value={removeBalanceAmount}
                  onChange={(e) =>
                    setRemoveBalanceAmount(parseFloat(e.target.value))
                  }
                />
              </div>
            </div>
            <div className="name">Type</div>
            <div className="group-input">
              <CustomSelect
                options={residentialPlans.map((plan) => plan.title)}
                handler={setRemoveBalanceType}
                selected={removeBalanceType}
              />
            </div>
            <div className="name">Username</div>
            <div className="group-input">
              <input
                type="text"
                className="input-balance"
                placeholder="Username"
                value={removeBalanceUsername}
                onChange={(e) => setRemoveBalanceUsername(e.target.value)}
              />
            </div>
            <button
              className="add-balance"
              onClick={() =>
                changeBalance(
                  removeBalanceUsername,
                  removeBalanceType,
                  removeBalanceAmount,
                  true
                )
              }
            >
              Remove Balance
            </button>
          </div>
        </div>
        <div className="col-sm-12 col-md-8">
          <div className="balance-card3">
            <div className="balance-header">
              <div className="balance-title1">Users</div>
              <div className="group-input">
                <input
                  type="text"
                  className="input-balance"
                  placeholder="Username"
                  onChange={(e) => {
                    if (!e.target.value) {
                      setFilteredUsers(users);
                      return;
                    }
                    const fuse = new Fuse(users, searchOptions);
                    setFilteredUsers(
                      fuse
                        .search(e.target.value)
                        .map((searchItem) => searchItem.item)
                    );
                  }}
                />
              </div>
            </div>
            <div></div>
            <div className="balance-content">
              <table
                className="my-table table-responsive"
                style={{ width: "100%" }}
              >
                <thead className="table-header">
                  <tr style={{ textAlign: "center" }}>
                    <th className="table-head">ID</th>
                    <th className="table-head">Username</th>
                    <th className="table-head">Email</th>
                    <th className="table-head">Balance</th>
                    <th className="table-head">Rank</th>
                    <th className="table-head">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.map((user, idx) => (
                    <User
                      key={idx}
                      user={user}
                      userClicked={(username: string) => {
                        setAddBalanceUsername(username);
                        setRemoveBalanceUsername(username);
                      }}
                      userBanned={(id: number) => banUser(id)}
                    ></User>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Balance;
