import { FC } from "react";
import { toast } from "react-toastify";

const ChangePassword: FC = () => {
  const changePassword = () => {
    const password = (document.getElementById("password") as HTMLInputElement)
      .value;
    const newPassword = (
      document.getElementById("new-password") as HTMLInputElement
    ).value;

    const payload = {
      password: password,
      new_password: newPassword,
    };

    const token = localStorage.getItem("token");

    fetch("https://api.infinity-supply.eu/api/user/changepassword", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.status >= 400) {
          toast((await response.json())["message"]);
          return;
        }

        toast("Successfully changed your password");
        (document.getElementById("new-password") as HTMLInputElement).value = "";
        (document.getElementById("password") as HTMLInputElement).value = ""
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="balance-part">
      <div className="dashboard-card login">
        <div className="title">Change Password</div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            changePassword();
          }}
        >
          <div className="group-input w-100">
            <input
              id="password"
              type="password"
              placeholder="Enter Password"
              required
            />
          </div>
          <div className="form-bottom">
            <div className="group-input">
              <input
                id="new-password"
                type="password"
                placeholder="Enter New Password"
              />
            </div>
            <button className="btn-card">{">"}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
