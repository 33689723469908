import { FC } from "react";
import { toast } from "react-toastify";
import { getTokenFromStorage } from "../../utils/util";

const Remove: FC<{ setCard: Function; plan: Plan }> = ({ setCard, plan }) => {
  const updatePlanLimit = () => {
    const token = getTokenFromStorage();
    fetch("https://api.infinity-supply.eu/api/user/planlimit", {
      method: "POST",
      body: JSON.stringify({
        limit: -1,
        type: plan.type,
      }),
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.status >= 400) {
          toast((await response.json())["message"]);
          return;
        }

        setCard("success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="w-100 h-100 fade-in">
      <div className="top">
        <div className="title">Remove plan limit</div>
        <div className="close" onClick={() => setCard("content")}></div>
      </div>
      <div className="middle">
        <div className="group-input">
          <div className="text">
            Are you sure you want to remove plan limit?
          </div>
          <button className="btn-main" onClick={updatePlanLimit}>
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

export default Remove;
