import { FC, useEffect, useState } from "react";
import Up from "../../assets/icons/keyboard_arrow_up.svg";
import Down from "../../assets/icons/keyboard_arrow_down.svg";
import { formatDate, getTokenFromStorage } from "../../utils/util";
import CustomSelect from "../../components/CustomSelect";
import { toast } from "react-toastify";

import {
  getDatacenterPlanName,
  getDatacenterPlanAndVariantFromProductID,
} from "../../utils/util";

const Row: FC<{ order: DatacenterOrder; subnets: Subnet[] }> = ({
  order,
  subnets,
}) => {
  const [state, setState] = useState("down");
  const open = (e: any) => {
    e.preventDefault();
    setState("up");
  };
  const close = (e: any) => {
    e.preventDefault();
    setState("down");
  };

  const collapse = () => {
    if (state === "up") {
      setState("down");
    } else {
      setState("up");
    }
  };

  const beautifySubnet = (subnet: string): string => {
    return subnet.substring(0, subnet.indexOf("-") - 1) + "0-254";
  };

  const servers = Array.from(
    new Set(subnets.map((subnet) => subnet.server))
  ).map((server) => ({
    displayName: `${server} (Server) / ${subnets
      .filter((subnet) => subnet.server === server)
      .map((subnet) => subnet.instock)
      .reduce((prev, current) => prev + current)} Free`,
    server: server,
    subnet: "",
    instock: subnets
      .filter((subnet) => subnet.server === server)
      .map((subnet) => subnet.instock)
      .reduce((prev, current) => prev + current),
  }));

  const assignOptions: AssignOption[] = servers.concat(
    subnets.map((subnet) => ({
      displayName: `${beautifySubnet(subnet.subnet)} / ${subnet.instock} Free`,
      server: subnet.server,
      subnet: subnet.subnet,
      instock: subnet.instock,
    }))
  );

  const [assignOption, setAssignOption] = useState({} as AssignOption);

  const assignProxies = () => {
    const orderPlanVariant = getDatacenterPlanAndVariantFromProductID(
      order.subscription_plan_id
    )[1];
    const token = getTokenFromStorage();

    if (!assignOption.server) {
      toast("Please pick an option to assign proxies from first.");
      return;
    }

    const payload = {
      order_id: order.id,
      server: assignOption.server,
      subnet: assignOption.subnet,
      amount: orderPlanVariant.amount,
    };

    fetch("https://api.infinity-supply.eu/api/admin/assigndatacenter", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.status >= 400) {
          toast((await response.json())["message"]);
          return;
        }

        toast("You've successfully assigned proxies to this user");
        setTimeout(() => (window.location.href = "./"), 500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const refreshProxies = () => {
    const token = getTokenFromStorage();

    if (!order.authtoken) {
      toast("Please first assign proxies before regenerating them");
      return;
    }

    const payload = {
      order_id: order.id,
    };

    fetch("https://api.infinity-supply.eu/api/admin/refreshdatacenter", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.status >= 400) {
          toast((await response.json())["message"]);
          return;
        }

        toast("You've successfully refreshed this order's details");
        setTimeout(() => (window.location.href = "./"), 500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStatus = (order: DatacenterOrder): string => {
    if (order.subscription.cancelled) return "Cancelled"

    return order.authtoken !== "" ? "Complete" : "Incomplete"
  }

  return (
    <>
      <tr
        className="animate__animated animate__fadeInUp"
        onClick={() => collapse()}
      >
        <td>{order.id}</td>
        <td style={{ width: 400 }}>
          {getDatacenterPlanName(
            getDatacenterPlanAndVariantFromProductID(order.subscription_plan_id)
          )}
        </td>
        <td>{order.authtoken ? order.authtoken : "-"}</td>
        <td>{formatDate(order.subscription.next_bill_date)}</td>
        <td>{order.user.username}</td>
        <td className={order.authtoken !== "" ? "complete" : ""}>
          {getStatus(order)}
        </td>
        <td>
          {state === "up" ? (
            <img src={Up} alt="up" onClick={(e) => close(e)} />
          ) : (
            <img src={Down} alt="down" onClick={(e) => open(e)} />
          )}
        </td>
      </tr>
      {state === "up" && (
        <tr>
          <td className="form-case" colSpan={2}>
            <div className="form">
              <div className="group-input">
                <CustomSelect
                  options={assignOptions.map(
                    (assignOption) => assignOption.displayName
                  )}
                  handler={(displayName: string) => {
                    setAssignOption(
                      assignOptions.filter(
                        (assignOption) =>
                          assignOption.displayName === displayName
                      )[0]
                    );
                  }}
                  selected={
                    assignOption.displayName
                      ? assignOption.displayName
                      : "Source"
                  }
                />
              </div>
              <button className="btn" onClick={assignProxies}>
                Submit
              </button>
              <button className="btn" onClick={refreshProxies}>
                Refresh
              </button>
            </div>
          </td>
          <td colSpan={6}>
            <div className="cont">
              <div className="square-log">
                {order.proxies
                  ? order.proxies.split("\n").map((proxy, i) => {
                      return (
                        <div key={i}>
                          {proxy}:{order.username}:{order.password}
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </td>
        </tr>
      )}
      <tr className="devider animate__animated animate__fadeInUp">
        <td colSpan={6}></td>
      </tr>
    </>
  );
};

export default Row;
