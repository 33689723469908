import { FC, useState, useEffect } from "react";
import { toast } from "react-toastify";
import CustomSelect from "../../../components/CustomSelect";
import { getTokenFromStorage } from "../../../utils/util";

const UserProxy: FC = () => {
  const [stockPlan, setStockPlan] = useState({} as Stock);
  const [val, setVal] = useState(0);

  const [stock, setStock] = useState([] as Stock[]);
  useEffect(() => {
    fetchStock();
  }, []);

  const fetchStock = () => {
    const token = getTokenFromStorage();
    fetch("https://api.infinity-supply.eu/api/user/stock", {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        const json = await response.json();
        setStock(json);
        setStockPlan(json[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateStock = (remove: boolean) => {
    const payload = {
      product_name: stockPlan.product_name,
      amount: remove ? val * -1 : val,
    };

    const token = getTokenFromStorage();
    fetch("https://api.infinity-supply.eu/api/admin/stock", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.status >= 400) {
          toast((await response.json())["message"]);
          return;
        }

        toast("Successfully changed stock");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="userproxy">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4">
          <div className="userproxy-card">
            <div className="userproxy-title1">Edit Datacenter Stock</div>
            <div className="userproxy-title2">Select Plan</div>
            <div className="group-input">
              <CustomSelect
                options={stock.map((stock) => stock.product_name)}
                handler={(planName: string) => {
                  const newPlan = stock.filter(
                    (stock) => stock.product_name === planName
                  )[0];
                  setStockPlan(newPlan);
                }}
                selected={stockPlan.product_name}
              />
            </div>
            <input
              type="number"
              className="input-text"
              value={val}
              onChange={(e) => setVal(parseInt(e.target.value))}
            />
            <div className="btn-groups">
              <button className="add" onClick={() => updateStock(false)}>
                Add Stock
              </button>
              <button className="delete" onClick={() => updateStock(true)}>
                Remove Stock
              </button>
            </div>
          </div>
        </div>

        {/* <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8">
          <div className="userproxy-card1">
            <div className="userproxy-title1">Show Proxies of User</div>
            <div className="header-group">
              <div className="row">
                <div className="col-sm-4 col-md-4">
                  <input
                    type="text"
                    className="input"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                  />
                </div>
                <div className="col-sm-4 col-md-4">
                  <div className="group-input">
                    <CustomSelect
                      options={datacenterPlans.map((plans) => plans.title)}
                      handler={(plan: string) => {
                        setPlan(
                          datacenterPlans.filter(
                            (plans) => plans.title === plan
                          )[0]
                        );
                      }}
                      selected={plan.title}
                    />
                  </div>
                </div>
                <div className="col-sm-4 col-md-4">
                  <div className="group">
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <button className="search">Search</button>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <button className="copy">Copy Proxies</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="boxes" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default UserProxy;
