import { FC, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "../../assets/big-logo.svg";
import Discord from "../../assets/discord.svg";
import "./style.scss";
import { toast } from "react-toastify";
import { getTokenFromStorage, onChangeSetState } from "../../utils/util";
import { discordLoginURL } from "../../config";

const Login: FC = () => {
  const { search } = useLocation();
  const history = useHistory();

  const token = getTokenFromStorage();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(true);

  const searchParams = new URLSearchParams(search);
  const code = searchParams.get("code");

  useEffect(() => {
    if (token) {
      history.push("/dashboard");
      return;
    }

    if (code) {
      const payload = {
        method: "discord",
        discord_code: code,
        redirect_uri: "https://dash.infinity-supply.eu/login",
      };

      fetch("https://api.infinity-supply.eu/api/login", {
        method: "POST",
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          if (response.status >= 400) {
            toast((await response.json())["message"]);
            return;
          }

          const json = await response.json();
          const token = json.token;

          if (remember) {
            localStorage.setItem("token", token);
          } else {
            sessionStorage.setItem("token", token);
          }

          window.location.href = "/dashboard";
          toast("Succesfully logged in");
        })
        .catch((error) => {
          console.log(error);
        });
      return;
    }
  });

  const login = () => {
    const payload = {
      method: "email",
      email: email,
      password: password,
    };

    fetch("https://api.infinity-supply.eu/api/login", {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        if (response.status >= 400) {
          toast((await response.json())["message"]);
          return;
        }

        const json = await response.json();
        const token = json.token;

        if (remember) {
          localStorage.setItem("token", token);
        } else {
          sessionStorage.setItem("token", token);
        }

        window.location.href = "/dashboard";
        toast("Succesfully logged in");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="auth-page login-page">
      <div className="content fade-in">
        <div className="title">
          <span className="white">Welcome, </span>
          <span className="blue">Login to continue</span>
        </div>
        <div className="auth-card">
          <form
            onSubmit={(event) => {
              event.preventDefault();
              login();
            }}
          >
            <div className="inputs">
              <input
                id="email"
                type="email"
                placeholder="Email"
                value={email}
                onChange={onChangeSetState(setEmail)}
                required
              />
              <input
                id="password"
                type="password"
                placeholder="Password"
                value={password}
                onChange={onChangeSetState(setPassword)}
                required
              />
            </div>
            <div className="check-group">
              <div className="check">
                <label className="check-container">
                  <input
                    type="checkbox"
                    id="remember"
                    checked={remember}
                    onChange={(event) => {
                      setRemember(event.target.checked);
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <label className="label" htmlFor="remember">
                Remember me
              </label>
            </div>
            <div className="w-100 d-flex">
              <button id="login-button" className="mx-auto" type="submit">
                Login
              </button>
            </div>
          </form>
          <div className="login-with w-100 d-flex">
            <span className="mx-auto">Login with</span>
            <div className="buttons mx-auto">
              <button
                onClick={() => {
                  window.location.href = discordLoginURL;
                }}
              >
                <img src={Discord} alt="discord" />
                Discord
              </button>
            </div>
          </div>
          <div className="link-group">
            <span>Dont have a account? </span>
            <Link to="/register" className="auth-link">
              Sign up
            </Link>
          </div>
        </div>
        <div className="w-100 d-flex">
          <img src={Logo} alt="logo" className="mx-auto logo" />
        </div>
      </div>
    </div>
  );
};

export default Login;
