import { FC, useState } from 'react'
import { toast } from 'react-toastify'
import {
  formatDate,
  getDatacenterPlanAndVariantFromProductID,
} from '../../utils/util'

const Card: FC<{ order: DatacenterOrder }> = ({ order }) => {
  const [view, setView] = useState(false)
  const [
    orderPlan,
    orderPlanVariant,
  ] = getDatacenterPlanAndVariantFromProductID(order.subscription_plan_id)

  const copyProxies = () => {
    navigator.clipboard
      .writeText(
        order.proxies
          .split('\n')
          .map((proxy) => `${proxy}:${order.username}:${order.password}`)
          .join('\n'),
      )
      .then(() => {
        toast('Successfully copied proxies to your clipboard')
      })
  }

  const exportProxies = () => {
    const url = window.URL.createObjectURL(
      new Blob([
        order.proxies
          .split('\n')
          .map((proxy) => `${proxy}:${order.username}:${order.password}`)
          .join('\n'),
      ]),
    )
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url

    a.download = 'proxies.txt'
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
  }

  return (
    <div className="main-card plan-card animate__animated animate__fadeInUp">
      <div className="plan-card-content">
        <div className="left">
          <div className="title">{orderPlan.title}</div>
          <div className="number">{orderPlanVariant.amount}</div>
          <div className="status">
            Subscription{' '}
            <span className={order.subscription.cancelled ? '' : 'active'}>
              {order.subscription.cancelled ? 'Cancelled' : 'Active'}
            </span>
          </div>
          <div className="date">
            Valid Until{' '}
            <span>{formatDate(order.subscription.next_bill_date)}</span>
          </div>
        </div>
        <div className="right">
          <button
            className="btn"
            onClick={() => window.open(order.subscription.manage_url)}
          >
            Manage
          </button>
          <button className="btn" onClick={() => setView(!view)}>
            View
          </button>
        </div>
      </div>
      {view ? (
        <>
          <div>
            <div className="cont">
              <div className="square-log">
                {order.proxies
                  ? order.proxies.split('\n').map((proxy) => {
                      return (
                        <div>
                          {proxy}:{order.username}:{order.password}
                        </div>
                      )
                    })
                  : ''}
                {order.proxies ? null : "This order hasn't been processed yet"}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="bottom-buttons">
              <button className="btn" onClick={copyProxies}>
                Copy
              </button>
              <button className="btn" onClick={exportProxies}>
                Export
              </button>
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default Card
