import { FC } from "react";

const CheckoutItem: FC<{user: User, userClicked: Function, userBanned: Function}> = ({user, userClicked, userBanned}) => {
  return (
    <tr style={{ textAlign: "center" }}>
      <td className="table-body">{user.id}</td>
      <td style={{"cursor": "pointer"}} className="table-body" onClick={() => {userClicked(user.username)}}>{user.username}</td>
      <td className="table-body">{user.email}</td>
      <td className="table-body">{((user.subuser.plans.map(plan => plan.data_total).reduce((a,b)=> a+b, 0)-user.subuser.plans.map(plan => plan.data_used).reduce((a,b)=> a+b, 0))/(2**30)).toFixed(2)} GB</td>
      <td className="table-body">{user.admin ? "Admin" : (user.member ? "Member": "User")}</td>
      <td style={{ display: "flex", justifyContent: "center" }}>
        <tr>
          <td>
            <button className="ban-balance" onClick={() => userBanned(user.id)}>Ban</button>
          </td>
        </tr>
      </td>
    </tr>
  );
};

export default CheckoutItem;
