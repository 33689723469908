import { useEffect } from "react";
import { useState } from "react";
import { FC } from "react";
import { infinityClubMembership } from "../../config";
import { getTokenFromStorage } from "../../utils/util";

const MembershipPlan: FC = () => {
  const [subscription, setSubscription] = useState(
    undefined as unknown as Subscription
  );

  useEffect(() => {
    const token = getTokenFromStorage();

    fetch("https://api.infinity-supply.eu/api/user/subscriptions", {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        const json: Subscription[] = await response.json();
        const membershipSubscriptions = json.filter(
          (subscription) =>
            subscription.subscription_plan_id ===
            infinityClubMembership.product_id
        );
        if (membershipSubscriptions.length > 0) {
          setSubscription(membershipSubscriptions[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (subscription) {
    return (
      <div className="col-lg-12">
        <div className="overview-content membership">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h4 className="name mb-3">INFINITY CLUB MEMBERSHIP</h4>
              <span className="total-title d-block status">
                Status{" "}
                <span className={subscription.cancelled ? "" : "active"}>
                  {subscription.cancelled ? "Cancelled" : "Active"}
                </span>
              </span>

              <span className="total-title">
                Valid Until{" "}
                <span className="text-white">
                  {subscription.next_bill_date}
                </span>
              </span>
            </div>

            <div className="col-md-6 d-flex justify-content-end mt-md-0 mt-2">
              <button
                style={{
                  background: "#07080d",
                  borderRadius: "5px",
                  transition: "0.3s",
                  border: "0px",
                  outline: "0px",
                  height: "44px",
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#92f8ff",
                  width: "60%",
                }}
                onClick={() => window.open(subscription.manage_url)}
              >
                Manage
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default MembershipPlan;
