import React, { FC} from 'react';
import { useHistory } from 'react-router-dom';
import Icon from '../../assets/icons/add.svg'

const AddPlan: FC = () => {
    const history = useHistory()
    const redirectToShop = () => {
        history.push("/shop")
    }

    return (
        <div className="dashboard-card add-plan" onClick={redirectToShop}>
            <img src={Icon} alt="Add" />
            <span>Add a proxy plan</span>
        </div>
    )
}

export default AddPlan;
