import React, { FC } from "react";
import "./style.scss";

const Paid: FC<{ amount: number }> = ({ amount }) => {
  return (
    <div className="switch-cards">
      <div className="top">
        <div className="titles">
          <div className="header">Paid Users</div>
          <div className="detail">{amount}</div>
        </div>
      </div>
    </div>
  );
};

export default Paid;
