import { FC, useState } from "react";
import CustomSelect from "../../components/CustomSelect";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import AnimatedNumber from "react-animated-number";

import Network from "../../assets/icons/network_check_active.svg";

const ShopItem: FC<{
  isMember: boolean | undefined;
  plans: ResidentialPlan[];
  onPurchase: Function;
}> = ({ isMember, plans, onPurchase }) => {
  const [selectedPlan, setSelectedPlan] = useState(plans[0]);
  const [sliderValue, setSliderValue] = useState(10);

  const calculatePricePerGigabyte = (plan: ResidentialPlan, isMember: boolean | undefined): number => {
    return !isMember ? plan.price : plan.discounted_price;
  }

  return (
    <div className="shop-card" style={{ height: "unset" }}>
      <div className="card-title">{selectedPlan.title}</div>
      <div className="card-detail mb-md-4 mb-3">
      {selectedPlan.description}
      </div>
      <div className="card-footer d-block">
        <h4 className="name mb-md-4 mb-3">Choose Product:</h4>
        <div className="group-input">
          <CustomSelect
            options={["Residential Proxies (Resis)"]}
            handler={() => {}}
            selected={"Residential Proxies (Resis)"}
          />
        </div>

        <div className="group-input mt-3">
          <CustomSelect
            options={
              plans.map(plan => plan.title)
          }
            handler={(option: string) => {
              setSelectedPlan(plans.filter(plan => plan.title === option)[0])
            }}
            selected={
              <>
                <img src={Network} alt="" />
                {selectedPlan.title}
              </>
            }
          />
        </div>

        <h1 className="price">
          <AnimatedNumber
            value={sliderValue * calculatePricePerGigabyte(selectedPlan, isMember)}
            formatValue={(n: number) => n.toFixed(0)}
            className="price"
          />
          .00 € / One Time
        </h1>
        <p className="price2">{calculatePricePerGigabyte(selectedPlan, isMember)} € per GB incl. VAT</p>

        <div className="slider-wrap residential-slider-wrap">
          <label>Quantity</label>
          <Slider
            min={1}
            max={100}
            value={sliderValue}
            defaultValue={sliderValue}
            marks={{
              // 1: <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 GB</>,
              // 50: "50 GB",
              // 100: "100 GB",
            }}
            step={1}
            onChange={(value: any) => {
              setSliderValue(value);
            }}
          />
          <span className="current-value" style={{left: `${sliderValue-5}%`}}>{sliderValue} GB</span>
        </div>

        <div className="card-detail mt-5">
          For Business Customers VAT can be removed at Checkout. We offer B2B 0%
          VAT Worldwide.
        </div>

        <button
          className="purchase mt-4 d-block ms-auto"
          onClick={() =>
            onPurchase(
              selectedPlan,
              sliderValue
            )
          }
        >
          Purchase
        </button>
      </div>
    </div>
  );
};

export default ShopItem;
