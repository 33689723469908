import DashboardActiveIcon from "../assets/icons/donut_small_active.svg";
import DatacenterIconActive from "../assets/icons/document_active.svg";
import ShopIconActive from "../assets/icons/shopping_cart_active.svg";
import AccountIconActive from "../assets/icons/account_circle_active.svg";
import AdminIconActive from "../assets/icons/admin_panel_settings_active.svg";

import DashboardIcon from "../assets/icons/donut_small.svg";
import DatacenterIcon from "../assets/icons/document.svg";
import ShopIcon from "../assets/icons/shopping_cart.svg";
import AccountIcon from "../assets/icons/account_circle.svg";
import AdminIcon from "../assets/icons/admin_panel_settings.svg";

type RouteItem = {
  title: string;
  link: string;
  icon: string;
  activeIcon: string;
  style: string;
  admin?: boolean;
  prefix: string;
};

const routes: RouteItem[] = [
  {
    title: "Dashboard",
    link: "/",
    activeIcon: DashboardActiveIcon,
    icon: DashboardIcon,
    style: "icon-dashboard",
    prefix: "dashboard",
  },
  {
    title: "Datacenter Proxies",
    link: "/",
    activeIcon: DatacenterIconActive,
    icon: DatacenterIcon,
    style: "icon-datacenter",
    prefix: "data_center_proxies",
  },
  {
    title: "Shop",
    link: "/",
    activeIcon: ShopIconActive,
    icon: ShopIcon,
    style: "icon-shop",
    prefix: "shop",
  },
  {
    title: "My account",
    link: "/",
    activeIcon: AccountIconActive,
    icon: AccountIcon,
    style: "icon-account",
    prefix: "my_account",
  },
  {
    title: "Admin",
    link: "/",
    activeIcon: AdminIconActive,
    icon: AdminIcon,
    style: "icon-admin",
    prefix: "admin",
    admin: true,
  },
  {
    title: "Datacenter Orders",
    link: "/",
    activeIcon: DatacenterIconActive,
    icon: DatacenterIcon,
    style: "icon-document",
    prefix: "datacenter_orders",
    admin: true,
  },
];

export default routes;
