import { FC } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../assets/big-logo.svg";

const Register: FC = () => {
  const register = () => {
    const username = (document.getElementById("username") as HTMLInputElement)
      .value;
    const email = (document.getElementById("email") as HTMLInputElement).value;
    const password = (document.getElementById("password") as HTMLInputElement)
      .value;
    const passwordConfirm = (
      document.getElementById("password-confirm") as HTMLInputElement
    ).value;

    if (password != passwordConfirm) {
      toast("Your passwords don't match");
      return;
    }

    const payload = {
      username,
      email,
      password,
    };

    fetch("https://api.infinity-supply.eu/api/register", {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        if (response.status >= 400) {
          toast((await response.json())["message"]);
          return;
        }

        toast("You've successfully signed up");
        (document.getElementById("username") as HTMLInputElement).value = "";
        (document.getElementById("email") as HTMLInputElement).value = "";
        (document.getElementById("password") as HTMLInputElement).value = "";
        (
          document.getElementById("password-confirm") as HTMLInputElement
        ).value = "";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="auth-page register-page">
      <div className="content fade-in">
        <div className="title">
          <span className="white">To get started, </span>
          <span className="blue">Sign up</span>
        </div>
        <div className="auth-card">
          <form
            onSubmit={(event) => {
              event.preventDefault();
              register();
            }}
          >
            <div className="inputs">
              <input
                id="username"
                type="text"
                placeholder="Username"
                required
              />
              <input id="email" type="email" placeholder="Email" required />
              <input
                id="password"
                type="password"
                placeholder="Password"
                required
              />
              <input
                id="password-confirm"
                type="password"
                placeholder="Confirm password"
              />
            </div>
            <div className="check-group">
              <div className="check">
                <label className="check-container">
                  <input type="checkbox" id="terms" required />
                  <span className="checkmark"></span>
                </label>
              </div>
              <label className="label" htmlFor="terms">
                I have read and agree to Infinity Supply’s
                <a
                  href="https://infinity-supply.eu/terms-of-service"
                  className="auth-link"
                >
                  Terms of Service
                </a>
              </label>
            </div>
            <div className="w-100 d-flex">
              <button id="register-button" className="mx-auto">
                Sign up
              </button>
            </div>
          </form>
          <div className="link-group">
            <span>Already have a account? </span>
            <Link to="/login" className="auth-link">
              Login
            </Link>
          </div>
        </div>
        <div className="w-100 d-flex">
          <img src={Logo} alt="logo" className="mx-auto logo" />
        </div>
      </div>
    </div>
  );
};

export default Register;
