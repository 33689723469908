import { FC } from "react";
import Icon from "../../assets/icons/network.svg";
import Invoice from "../../assets/icons/invoice.svg";
import { formatDate } from "../../utils/util";
import "./style.scss";

const CheckoutItem: FC<{checkout: Checkout, withInvoice: boolean}> = ({ checkout, withInvoice }) => {
  return (
    <div
      className={`checkout-item mb-3 animate__animated animate__fadeInUp ${
        withInvoice ? "with-invoice" : ""
      }`}
    >
      <div className="icon my-auto">
        <img src={Icon} alt="assignment late" />
      </div>
      <div className="title-date">
        <span className="title">{withInvoice ? checkout.product_name : checkout.product_name.substring(0,10)+"..."}</span>
        <span className="date">{formatDate(checkout.created_at.toString())}</span>
      </div>
      {withInvoice && (
        <div
          className="invoice"
          onClick={() => {
            window.open(
              `https://my.paddle.com/invoice/${checkout.order_id}/${checkout.checkout_id}`,
              "_blank"
            );
          }}
        >
          <img src={Invoice} alt="invoice" />
          <span>Invoice</span>
        </div>
      )}
      <div className="price" style={{"minWidth": "100px"}}>{checkout.price}</div>
    </div>
  );
};

export default CheckoutItem;
