import { FC, useEffect, useState } from "react";
import CheckoutItem from "../../components/CheckoutItem";
import { getTokenFromStorage } from "../../utils/util";

const Checkouts: FC = () => {
  const [checkouts, setCheckouts] = useState([] as Checkout[]);

  useEffect(() => {
    const token = getTokenFromStorage();
    fetch("https://api.infinity-supply.eu/api/user/checkouts", {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        const json = await response.json();
        json.reverse();
        setCheckouts(json);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="dashboard-card checkouts">
      <div className="title mb-4">Order History</div>
      <div className="checks mt-4">
        {checkouts.map((item, idx) => (
          <CheckoutItem key={"checkout-item-" + idx} withInvoice={false} checkout={item} />
        ))}
      </div>
    </div>
  );
};

export default Checkouts;
