import { toast } from "react-toastify";
import { FC, useEffect, useState } from "react";
import "./style.scss";
import NavBar from "../../components/Navbar";
import shopIcon from "../../assets/icons/network.svg";
import closeButtonIcon from "../../assets/icons/close.svg";

import {
  datacenterPlans,
  infinityClubMembership,
  residentialPlans,
} from "../../config";
import { financialSubtraction, getTokenFromStorage } from "../../utils/util";

import ResidentialShop from "./ResidentialShop";
import DatacenterShop from "./DatacenterShop";

const Shop: FC<{ user: User | undefined }> = ({ user }) => {
  const [openPurchase, setOpenPurchase] = useState(false);
  const [stock, setStock] = useState([] as Stock[]);

  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [currency, setCurrency] = useState("EUR");
  const [subtotal, setSubtotal] = useState(-1);
  const [discountedPrice, setDiscountedPrice] = useState(-1);
  const [taxes, setTaxes] = useState("Calculated at checkout");

  const eventCallback = (event: any) => {
    switch (event.event) {
      // If you have country, set tax rate for country
      case "Checkout.Location.Submit":
        setCurrency(event.eventData.checkout.prices.customer.currency);
        setSubtotal(
          financialSubtraction(
            event.eventData.checkout.prices.customer.total,
            event.eventData.checkout.prices.customer.total_tax
          )
        );
        setTaxes(event.eventData.checkout.prices.customer.total_tax);
        break;
      // If you have VAT, fuck taxes
      case "Checkout.Vat.Applied":
        setTaxes(event.eventData.checkout.prices.customer.total_tax);
        break;
      // If you remove VAT and try to finesse, go back to your shit country and pay taxes
      case "Checkout.Vat.Remove":
        setTaxes(event.eventData.checkout.prices.customer.total_tax);
        break;
      // If you add coupon code, show it
      case "Checkout.Coupon.Applied":
        setDiscountedPrice(
          financialSubtraction(
            event.eventData.checkout.prices.customer.total,
            event.eventData.checkout.prices.customer.total_tax
          )
        );
        setTaxes(event.eventData.checkout.prices.customer.total_tax);
        break;
      case "Checkout.Coupon.Remove":
        setDiscountedPrice(-1);
        setSubtotal(
          financialSubtraction(
            event.eventData.checkout.prices.customer.total,
            event.eventData.checkout.prices.customer.total_tax
          )
        );
        setTaxes(event.eventData.checkout.prices.customer.total_tax);
        break;
    }
  };

  const purchaseResidentialData = (
    plan: ResidentialPlan,
    amount: number
  ) => {
    if (!user) {
      toast(
        "There was an error getting your user. Refresh or relogin. If this issue persists contact staff."
      );
      return;
    }
    const price = (user?.member ? plan.discounted_price : plan.price) * amount;
    setProductName(`${plan.title} ${amount}GB`);
    setProductPrice(`${price} €`);
    setSubtotal(price);
    setTaxes("Calculated at checkout");
    setDiscountedPrice(-1);

    const token = getTokenFromStorage();
    fetch("https://api.infinity-supply.eu/api/user/buy", {
      method: "POST",
      body: JSON.stringify({
        type: "residential",
        product_id: plan.product_id,
        quantity: amount,
      }),
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.status >= 400) {
          toast((await response.json()).message);
          return;
        }

        const payLink = (await response.json()).paylink;

        setOpenPurchase(true);
        (window as any).Paddle.Checkout.open({
          method: "inline",
          passthrough: `{"id": ${user?.id}}`,
          override: payLink,
          frameTarget: "checkout-window",
          successCallback: () => {
            toast("Successfully purchased your product");
            setOpenPurchase(false);
          },
          closeCallback: () => {
            setOpenPurchase(false);
          },
          displayModeTheme: "dark",
          frameStyle:
            "height:100%; width:100%; min-width:312px; border: none; color: white;",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const purchaseDatacenter = (
    plan: DatacenterPlan,
    variant: DatacenterPlanVariant
  ) => {
    if (!user) {
      toast(
        "There was an error getting your user. Refresh or relogin. If this issue persists contact staff."
      );
      return;
    }

    setProductName(`${variant.amount} ${plan.title}`);
    setProductPrice(`${variant.price} €`);
    setSubtotal(variant.price);
    setTaxes("Calculated at checkout");
    setDiscountedPrice(-1);

    const token = getTokenFromStorage();
    fetch("https://api.infinity-supply.eu/api/user/buy", {
      method: "POST",
      body: JSON.stringify({
        type: "datacenter",
        product_id: variant.product_id,
      }),
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.status >= 400) {
          toast((await response.json()).message);
          return;
        }

        const payLink = (await response.json()).paylink;

        setOpenPurchase(true);
        (window as any).Paddle.Checkout.open({
          method: "inline",
          passthrough: `{"id": ${user?.id}}`,
          override: payLink,
          frameTarget: "checkout-window",
          successCallback: () => {
            toast("Successfully purchased your product");
            setOpenPurchase(false);
          },
          closeCallback: () => {
            setOpenPurchase(false);
          },
          displayModeTheme: "dark",
          frameStyle:
            "height:100%; width:100%; min-width:312px; border: none; color: white",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Deprecated
  // const purchaseMembership = () => {
  //   if (user?.member) {
  //     toast("You've already subscribed to this membership.");
  //     return;
  //   }

  //   setOpenPurchase(true);
  //   setProductName(infinityClubMembership.title);
  //   setProductPrice(`${infinityClubMembership.price} €`);
  //   setSubtotal(infinityClubMembership.price);
  //   setTaxes("Calculated at checkout");
  //   setDiscountedPrice(-1);

  //   (window as any).Paddle.Checkout.open({
  //     method: "inline",
  //     product: infinityClubMembership.product_id,
  //     passthrough: `{"id": ${user?.id}}`,
  //     frameTarget: "checkout-window",
  //     successCallback: () => {
  //       toast(
  //         "Successfully subscribed for our membership. You might have to refresh a couple times to see changes."
  //       );
  //       setOpenPurchase(false);
  //     },
  //     closeCallback: () => {
  //       setOpenPurchase(false);
  //     },
  //     displayModeTheme: "dark",
  //     frameStyle:
  //       "height:100%; width:100%; min-width:312px; border: none; color: white",
  //   });
  // };

  const fetchStock = () => {
    const token = getTokenFromStorage();
    fetch("https://api.infinity-supply.eu/api/user/stock", {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        const json = await response.json();
        setStock(json);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    (window as any).eventCallback = eventCallback;
    fetchStock();
  }, []);

  return (
    <div className="shop-content fade-in">
      <div className="row">
        <NavBar user={user} />
      </div>
      <div className="content mt-4">
        <div className="plan-option mb-5">
          <div className="row">
            <div className="title">What do you want to buy today ?</div>
          </div>
          <div className="shop-cards mt-4" style={{ width: "67.8%" }}>
            {
              <DatacenterShop
                plans={datacenterPlans}
                stock={stock}
                onPurchase={purchaseDatacenter}
              />
            }
            {user && <ResidentialShop
                    isMember={user?.member}
                    plans={residentialPlans}
                    onPurchase={purchaseResidentialData}
                  />}
          </div>
        </div>
      </div>

      <div className={`modal ${openPurchase ? "active" : "inactive"}`}>
        <div className="modal-inside-wrapper">
          <div className="modal-inside">
            <img
              id="close-button"
              src={closeButtonIcon}
              onClick={() => {
                setOpenPurchase(false);
              }}
            />

            <div className="order-details">
              Unlock the Power of Infinity Supply
              <div className="cart-details">
                Cart
                <div className="cart-item">
                  <img src={shopIcon} alt="Product Icon" />
                  <div className="cart-name">{productName}</div>
                  <div className="cart-price">{productPrice}</div>
                </div>
              </div>
              <div className="bill">
                <div className="list-item">
                  <div className="list-item-title">Subtotal</div>
                  <div className="list-item-price">
                    {subtotal.toFixed(2)} {currency}
                  </div>
                </div>
                {discountedPrice !== -1 ? (
                  <div className="list-item">
                    <div className="list-item-title">Discount</div>
                    <div className="list-item-price discount-price">
                      -{(subtotal - discountedPrice).toFixed(2)} {currency}
                    </div>
                  </div>
                ) : null}
                <div className="list-item">
                  <div className="list-item-title">
                    Taxes{" "}
                    {isNaN(+taxes)
                      ? ""
                      : `(${Math.round((100 * Number(taxes)) / subtotal)}%)`}
                  </div>
                  <div className="list-item-price">
                    {taxes} {isNaN(+taxes) ? "" : currency}
                  </div>
                </div>
                <div className="divider"></div>
                <div className="total-price">
                  <div className="total-title">Total (including tax)</div>
                  <div className="total-price-value">
                    {(
                      (discountedPrice !== -1 ? discountedPrice : subtotal) +
                      (isNaN(+taxes) ? 0 : Number(taxes))
                    ).toFixed(2)}{" "}
                    {currency}
                  </div>
                </div>
              </div>
            </div>
            <div className="checkout-window"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shop;
