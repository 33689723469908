import { FC, useState } from "react";
import NavBar from "../../components/Navbar";
import ResPlan from "../../components/ResPlan";
import { infinityResidentialPools } from "../../config";
import AddPlan from "./AddPlan";
import Announcements from "./Announcements";
import Checkouts from "./Checkouts";
import Filters from "./Filters";
import Log from "./Log";
import "./style.scss";

const Dashboard: FC<{ user: User | undefined }> = ({ user }) => {
  const [plan, setPlan] = useState("Infinity Residential");
  const [type, setType] = useState("Static");
  const [pool, setPool] = useState(infinityResidentialPools[0]);
  const [amount, setAmount] = useState(0);

  return (
    <div className="fade-in">
      <div className="row">
        <NavBar user={user} />
      </div>
      <div className="content">
        <div className="row mt-4">
          <div className="col-lg-3 col-md-6 plan">
            {user && user.subuser.plans.length >= 1 ? (
              <ResPlan plan={user.subuser.plans[0]} />
            ) : (
              <AddPlan />
            )}
          </div>
          <div className="col-lg-3 col-md-6 plan">
            {user && user.subuser.plans.length >= 2 ? (
              <ResPlan plan={user.subuser.plans[1]} />
            ) : (
              <AddPlan />
            )}
          </div>
          <div className="col-lg-3 col-md-6 plan">
            {user && user.subuser.plans.length >= 3 ? (
              <ResPlan plan={user.subuser.plans[2]} />
            ) : (
              <AddPlan />
            )}
          </div>
          <div className="col-lg-3 col-md-6 plan">
            {user && user.subuser.plans.length >= 4 ? (
              <ResPlan plan={user.subuser.plans[3]} />
            ) : (
              <AddPlan />
            )}
          </div>
          {/* <div className="col-lg-3 col-md-6">
            <Announcements />
          </div> */}
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <Filters
              user={user}
              plan={[plan, setPlan]}
              type={[type, setType]}
              pool={[pool, setPool]}
              amount={[amount, setAmount]}
            />
          </div>
          <div className="col-lg-6 col-xl-6 col-md-12">
            <Log
              user={user}
              plan={plan}
              type={type}
              pool={pool}
              amount={amount}
            />
          </div>
          <div className="col-lg-3 col-md-6 order-md-first order-lg-last">
            <Checkouts />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
