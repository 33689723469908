import { FC } from "react";
import CheckoutItem from "../../components/CheckoutItem";

const Checkouts: FC<any> = ({ checkouts }) => {
  return (
    <div className="dashboard-card checkouts">
      <div className="title mb-4">Order History</div>
      <div className="checks mt-4">
        {checkouts.map((checkout: any, idx: string) => (
          <CheckoutItem
            key={"checkout-item-" + idx}
            checkout={checkout}
            withInvoice
          />
        ))}
      </div>
    </div>
  );
};

export default Checkouts;
