import { FC, useState } from "react";
import { toast } from "react-toastify";
import { getTokenFromStorage, onChangeSetState } from "../../utils/util";

const Set: FC<{ setCard: Function; plan: Plan }> = ({ setCard, plan }) => {
  const [limit, setLimit] = useState("");

  const updatePlanLimit = () => {
    const token = getTokenFromStorage();
    fetch("https://api.infinity-supply.eu/api/user/planlimit", {
      method: "POST",
      body: JSON.stringify({
        limit: parseFloat(limit),
        type: plan.type,
      }),
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.status >= 400) {
          toast((await response.json())["message"]);
          return;
        }

        setCard("success")
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  return (
    <div className="w-100 h-100 fade-in">
      <div className="top">
        <div className="title">Set Plan limit</div>
        <div className="close" onClick={() => setCard("content")}></div>
      </div>
      <div className="middle">
        <div className="group-input">
          <input
            type="number"
            placeholder="Amount (in GB)"
            min={0}
            value={limit}
            onChange={onChangeSetState(setLimit)}
          />
          <button className="btn-main" onClick={updatePlanLimit}>
            Set
          </button>
        </div>
      </div>
    </div>
  );
};

export default Set;
