import React, { FC } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import routes from "../../routes/routes";
import SideItem from "./SideItem";
import Logo from "../../assets/small-logo.svg";
import LogoutIcon from "../../assets/icons/logout.svg";
import "./style.scss";

const SideBar: FC<{ user: User }> = ({ user }) => {
  const history = useHistory();
  const location = useLocation();
  let prefix = location.pathname.split("/")[1];

  const logout = () => {
    localStorage.removeItem("token");
    history.push("/login");
  };

  return (
    <div className="sidebar pt-2">
      <div className="logo my-4">
        <Link to="/dashboard">
          <div>
            <img src={Logo} alt="" />
          </div>
          <div>
            <h1>INFINITY</h1>
            <small>SUPPLY</small>
          </div>
        </Link>
      </div>
      <div className="divider mt-2"></div>
      <ul className="mt-4">
        {routes.map((item, idx) => {
          if (item.admin && (!user || !user.admin)) {
            return null;
          }

          return (
            <SideItem
              key={idx}
              title={item.title}
              icon={prefix === item.prefix ? item.activeIcon : item.icon}
              style={item.style}
              link={`/${item.prefix}${item.link}`}
              active={prefix === item.prefix}
            />
          );
        })}
      </ul>
      <div className="mt-4 d-flex">
        <div className="logout-btn mx-auto" onClick={() => logout()}>
          <img src={LogoutIcon} alt="logout" />
          <span>Logout</span>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
