import React, { FC, useEffect, useState } from "react";
import NavBar from "../../components/Navbar";
import CustomSelect from "../../components/CustomSelect";
import "./style.scss";
import Proxy from "./proxy";
import Earned from "./earned";
import Users from "./users";
import Paid from "./paid";
import Overview from "./Overview";
import Balance from "./Balance";
import UserProxy from "./UserProxy";
import { getTokenFromStorage } from "../../utils/util";

const Admin: FC<{ user: User }> = ({ user }) => {
  const [tab, setTab] = useState("Overview");
  const [statistics, setStatistics] = useState({} as Statistics);

  useEffect(() => {
    const token = getTokenFromStorage();
    fetch("https://api.infinity-supply.eu/api/admin/statistics", {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        const json = await response.json();
        setStatistics(json);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="admin fade-in">
      <div className="row">
        <NavBar user={user} />
      </div>
      <div className="content">
        <div className="admin-panel">
          <div className="title mt-4">Admin Panel</div>
        </div>
        <div className="top">
          <div className="switch-panel mr-25">
            <div className="title1 mt-4 ">Switch Panels</div>
            <div className="row">
              <div className="group-input">
                <CustomSelect
                  options={["Overview", "User Proxy", "Balance"]}
                  handler={setTab}
                  selected={tab}
                />
              </div>
            </div>
          </div>
          <Proxy
            amount={statistics.total_proxies ? statistics.total_proxies : 0}
          />
          {/* <Earned /> */}
          {/* <Spent /> */}
          <Users amount={statistics.total_users ? statistics.total_users : 0} />
          <Paid amount={statistics.paid_users ? statistics.paid_users : 0} />
        </div>
        <div className="card-content">
          {tab === "Overview" ? (
            <Overview />
          ) : tab === "User Proxy" ? (
            <UserProxy />
          ) : (
            <Balance />
          )}
        </div>
      </div>
    </div>
  );
};

export default Admin;
