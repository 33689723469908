import { FC } from "react";
import Avatar from "../../assets/avatar.svg";
import "./style.scss";

const NavBar: FC<{ user: User | undefined }> = ({ user }) => {
  return (
    <div className="main-navbar">
      <div className="current-user ms-auto">
        <div className="info">
          <span className="name">{user?.username}</span>
        </div>
        {/* <img src={Avatar} alt="avatar" /> */}
      </div>
    </div>
  );
};

export default NavBar;
