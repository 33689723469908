import { FC, ComponentType } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import SideBar from '../components/SideBar';

interface Props extends RouteProps {
    component: ComponentType;
}

const DashboardRoute: FC<Props & RouteProps & {user: User | undefined}> = ({ component: Component, path, ...rest }) => {
    return (
        <div className="dashboard-layout">
            <SideBar user={rest.user} />
            <div className="main-content">
                <Route {...rest} render={props => <Component {...props} user={rest.user} />} />
            </div>
        </div>
    );
}

export default DashboardRoute;