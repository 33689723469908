import { FC, useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Admin from "../scenes/Admin";
import Dashboard from "../scenes/Dashboard";
import DataCenterProxies from "../scenes/DataCenterProxies";
import MyAccount from "../scenes/MyAccount";
import Shop from "../scenes/Shop";
import DataCenterOrders from "../scenes/DataCenterOrders";
import DashboardRoute from "./DashboardRoute";
import Login from "../pages/Login";
import Register from "../pages/register";
import { getTokenFromStorage } from "../utils/util";
import { toast } from "react-toastify";

const Router: FC = () => {
  const location = useLocation();

  const [user, setUser] = useState();
  useEffect(() => {
    const token = getTokenFromStorage();
    if (!token) {
      if (
        window.location.href.includes("login") ||
        window.location.href.includes("signup")
      ) {
        return;
      }
      window.location.href = "/login";
    }

    fetch("https://api.infinity-supply.eu/api/user", {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.status >= 400) {
          toast(
            "There was a problem getting your user. You've been logged out"
          );
          sessionStorage.clear();
          localStorage.clear();
          return
        }
        const json = await response.json();
        setUser(json);
      })
      .catch((error) => {
        toast("There was a problem getting your user. You've been logged out");
        sessionStorage.clear();
        localStorage.clear();
        console.log(error);
      });
  }, []);

  return (
    <Switch location={location} key={location.pathname}>
      <DashboardRoute
        exact
        path={"/dashboard"}
        component={Dashboard}
        user={user}
      />
      <DashboardRoute
        exact
        path={"/data_center_proxies"}
        component={DataCenterProxies}
        user={user}
      />
      <DashboardRoute exact path={"/shop"} component={Shop} user={user} />
      <DashboardRoute
        exact
        path={"/my_account"}
        component={MyAccount}
        user={user}
      />
      <DashboardRoute exact path={"/admin"} component={Admin} user={user} />
      <DashboardRoute
        exact
        path={"/datacenter_orders"}
        component={DataCenterOrders}
        user={user}
      />
      <Route exact path={"/login"} component={Login} />
      <Route exact path={"/register"} component={Register} />
      <Redirect to="/login" />
    </Switch>
  );
};

export default Router;
